import { onValue, ref } from "firebase/database";
import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../api/firebase";
import { AppState } from "../models";

const AppStateContext = createContext<AppState>({
    round: 0,
    first: {
        score: 0,
        timer: 0
    },
    second: {
        question: null,
        answer: "",
        playerAnswer: "",
        chaserAnswer: "",
        questionShown: false,
        choicesShown: false,
        allowAnswers: false,
        answerStage: 0
    }
})

export const AppStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState<AppState>({
        round: 0,
        first: {
            score: 0,
            timer: 0
        },
        second: {
            question: null,
            answer: "",
            playerAnswer: "",
            chaserAnswer: "",
            questionShown: false,
            choicesShown: false,
            allowAnswers: false,
            answerStage: 0
        }
    });

    useEffect(() => {
        const query = ref(db, "/");
        return onValue(query, (snapshot) => {
            const newState = snapshot.val();
            if (!newState.first) {
                newState.first = state.first
            }
            if (!newState.second) {
                newState.second = state.second
            }
            setState(newState)
        }, (error) => console.log(error));
        // eslint-disable-next-line
    }, []);

    return <AppStateContext.Provider value={state}>
        {children}
    </AppStateContext.Provider>
}

export const AppStateConsumer: React.FC<{ children: (value: AppState) => React.ReactNode }> = ({ children }) => {
    return <AppStateContext.Consumer>
        {children}
    </AppStateContext.Consumer>
}

export const useAppState = () => {
    return useContext(AppStateContext)
}