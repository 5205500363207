import clsx from "clsx"
import { SecondRoundState } from "../../models"

import "./styles.scss"
import useSounds from "./useSounds"

export type QuestionBoxProps = {
    state: SecondRoundState
}

type ChoiceProps = {
    letter: string
    text: string
    playerAnswer: string
    chaserAnswer: string
    correctAnswer: string
    highlightCorrect: boolean
    highlightPlayer: boolean
    highlightChaser: boolean
}

const Choice: React.FC<ChoiceProps> = ({ letter, text, playerAnswer, chaserAnswer, correctAnswer, highlightChaser, highlightCorrect, highlightPlayer }) => {
    return (
        <div className={clsx(
            "choice",
            highlightCorrect && correctAnswer === letter && "choice-correct",
            highlightPlayer && playerAnswer === letter && "choice-player",
            highlightChaser && chaserAnswer === letter && "choice-chaser",
        )}>
            <div className="choice-letter">{letter}</div>
            <div className="choice-text">{text}</div>
        </div>
    )
}

const QuestionBox: React.FC<QuestionBoxProps> = ({ state }) => {
    const { playerAnswer, chaserAnswer, choicesShown, answerStage, questionShown } = state

    useSounds(state)

    if (!state.question) {
        return null
    }

    const { answerA, answerB, answerC, correctAnswer, text } = state.question

    const playerAnswered = playerAnswer !== ""
    const chaserAnswered = chaserAnswer !== ""
    const bothAnswered = playerAnswered && chaserAnswered

    const highlightPlayer = bothAnswered && answerStage >= 1
    const highlightCorrect = bothAnswered && answerStage >= 2
    const highlightChaser = bothAnswered && answerStage >= 3

    const commonChoiceProps = { chaserAnswer, playerAnswer, correctAnswer, highlightChaser, highlightPlayer, highlightCorrect }

    return <div className="question d-flex flex-column gap-3">
        <div className="d-flex flex-row justify-content-between">
            <div className={clsx("player", !playerAnswered && "hidden")}>Hráč</div>
            <div className={clsx("player", !chaserAnswered && "hidden")}>Lovec</div>
        </div>
        <div className={clsx("question-text", !questionShown && "question-hidden")}>
            {text}
        </div>
        <div className={clsx("d-flex flex-row gap-3", !choicesShown && "choices-hidden")}>
            <Choice letter="A" text={answerA} {...commonChoiceProps} />
            <Choice letter="B" text={answerB} {...commonChoiceProps} />
            <Choice letter="C" text={answerC} {...commonChoiceProps} />
        </div>
    </div>
}

export default QuestionBox