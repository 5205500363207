import clsx from 'clsx'
import './styles.scss'
import { Question } from '../../models'

export type GameButtonsProps = {
    question?: Question | null
    disabled?: boolean
    selectedAnswer?: string
    choicesShown?: boolean
    showCorrect?: boolean
    allowAnswers?: boolean
    onAnswerSelected: (answer: string) => void
}

export type ButtonProps = {
    label: string
    active?: boolean
    disabled?: boolean
    isCorrect?: boolean
    onSelected?: () => void
}

const Button: React.FC<ButtonProps> = ({ label, active = false, disabled = false, onSelected, isCorrect = false }) => {
    return <button
        disabled={disabled}
        className={clsx(
            "btn",
            "game-button",
            isCorrect ? "btn-success" : (disabled && active ? "btn-light" : "btn-outline-light"),
        )}
        onClick={() => onSelected && onSelected()}
        onTouchStart={() => onSelected && onSelected()}
    >
        {label}
    </button>
}

const GameButtons: React.FC<GameButtonsProps> = ({
    question,
    disabled = false,
    selectedAnswer = "",
    showCorrect = false,
    allowAnswers = false,
    choicesShown = false,
    onAnswerSelected
}) => {
    if (!question || !choicesShown) return null

    return <div className='d-flex flex-row justify-content-center w-100'>
        <div className='text-center' style={{ margin: "0px 5vw" }}>
            {allowAnswers ?
                <Button isCorrect={showCorrect && question.correctAnswer === "A"} disabled={disabled || selectedAnswer !== ""} active={selectedAnswer === "A"} label='A' onSelected={() => onAnswerSelected("A")} /> : null}
            <div className='mt-5' style={{ fontSize: "2vw", width: "20vw" }}><b>A:</b> {question.answerA}</div>
        </div>
        <div className='text-center' style={{ margin: "0px 5vw" }}>
            {allowAnswers ?
                <Button isCorrect={showCorrect && question.correctAnswer === "B"} disabled={disabled || selectedAnswer !== ""} active={selectedAnswer === "B"} label='B' onSelected={() => onAnswerSelected("B")} /> : null}
            <div className='mt-5' style={{ fontSize: "2vw", width: "20vw" }}><b>B:</b> {question.answerB}</div>
        </div>
        <div className='text-center' style={{ margin: "0px 5vw" }}>
            {allowAnswers ?
                <Button isCorrect={showCorrect && question.correctAnswer === "C"} disabled={disabled || selectedAnswer !== ""} active={selectedAnswer === "C"} label='C' onSelected={() => onAnswerSelected("C")} /> : null}
            <div className='mt-5' style={{ fontSize: "2vw", width: "20vw" }}><b>C:</b> {question.answerC}</div>
        </div>
    </div>
}

export default GameButtons