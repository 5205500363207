import "./styles.scss"
import Countdown from "../Countdown"
import useSounds from "./useSounds"

export type TimerBoxProps = {
    score: number
    timer: number
}

const formatScore = (score: number) =>
    score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

const TimerBox: React.FC<TimerBoxProps> = ({ score, timer }) => {
    useSounds({ score, timer })

    return <div className="timer-box d-flex flex-row gap-2">
        <div className="score">
            {formatScore(score)}
        </div>
        <div className="timer">
            {timer > 0 ? <Countdown until={timer * 1000} /> : "1:00"}</div>
    </div>
}

export default TimerBox