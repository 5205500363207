import { useEffect, useRef } from "react";
import { FirstRoundState } from "../../models";
import useSound from "use-sound";

import timerSound from './sounds/timer.mp3'
import correctAsnwerSound from './sounds/correctAnswer.mp3'

const useSounds = (state: FirstRoundState) => {
    const prevState = useRef<FirstRoundState>(state);

    const [playTimerSound, { stop }] = useSound(timerSound, { volume: 0.75 })
    const [playCorrectAnswerSound] = useSound(correctAsnwerSound)

    useEffect(() => {
        if (prevState.current.timer === 0 && state.timer > 0) {
            playTimerSound()
        } else if (prevState.current.timer !== state.timer) {
            stop()
        }

        if (prevState.current.score < state.score) {
            playCorrectAnswerSound()
        }

        prevState.current = state

        // eslint-disable-next-line
    }, [state])

}

export default useSounds