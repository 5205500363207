import clsx from "clsx";
import { useAddPoints, useNextStage, useResetFirstRound, useSelectRound, useShowNextQuestion, useStartTimer, useSubPoints } from "../api/client";
import { useAppState } from "../data/AppStateProvider"
import { FirstRoundState, SecondRoundState } from "../models";
import Countdown from "../components/Countdown";

import './HostPage.scss'
import { useCallback, useEffect, useState } from "react";

const formatScore = (score: number) =>
    score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")


const getSecondRoundStateString = (state: SecondRoundState) => {
    if (!state.question) {
        return "Žádné další otázky"
    }

    if (state.answerStage === 0) {
        if (state.chaserAnswer !== "" && state.playerAnswer !== "") return "Oba odpověděli";
        if (state.chaserAnswer !== "")
            return "Lovec odpověděl";
        if (state.playerAnswer !== "")
            return "Hráč odpověděl";
    }

    if (state.answerStage === 1)
        return "Zvýrazněna odpověď hráče"

    if (state.answerStage === 2)
        return "Zvýrazněna správná odpověď"

    if (state.answerStage === 3)
        return "Zvýrazněna odpověď lovce"

    if (!state.questionShown)
        return "Otázka čeká na položení"

    if (!state.choicesShown)
        return "Čtení otázky"

    if (!state.allowAnswers)
        return "Čtení odpovědí"

    return "Otázka položena"
}

type ChoiceProps = {
    letter: string
    text?: string
    state: SecondRoundState
}

const Choice: React.FC<ChoiceProps> = ({ state, letter, text }) => {
    const labels = [
        state.playerAnswer === letter && state.answerStage >= 1 && "HRÁČ",
        state.question?.correctAnswer === letter && state.answerStage >= 2 && "SPRÁVNÁ",
        state.chaserAnswer === letter && state.answerStage >= 3 && "LOVEC"
    ].filter(part => !!part).join(", ")

    return (
        <div>
            <b>{letter}: </b> <i className="me-1">{state.choicesShown && text}</i> <b>{labels ? `(${labels})` : ""}</b>
        </div>
    )
}

const SecondRound: React.FC<{ state: SecondRoundState }> = ({ state }) => {

    const nextStage = useNextStage();
    const showNextQuestion = useShowNextQuestion()

    const stateString = getSecondRoundStateString(state)

    const waitingForHost = !(state.allowAnswers && (state.chaserAnswer === "" || state.playerAnswer === ""))

    return (
        <div className="d-flex w-75 flex-column gap-2">
            <div className="mb-3"><b>Stav: </b>{stateString}</div>
            <div className="mb-3"><b>Otázka: </b> {state.questionShown && state.question?.text}</div>
            <Choice letter="A" text={state.question?.answerA} state={state} />
            <Choice letter="B" text={state.question?.answerB} state={state} />
            <Choice letter="C" text={state.question?.answerC} state={state} />

            <div className="d-flex flex-row justify-content-between gap-3 mt-5">
                <button onClick={() => showNextQuestion()} className="btn btn-danger">
                    Další otázka
                </button>
                <button disabled={!waitingForHost} onClick={() => nextStage()} className={clsx("btn", waitingForHost ? "btn-primary" : "btn-light")}>
                    Další stav
                </button>
            </div>
        </div>
    )
}

const FirstRound: React.FC<{ state: FirstRoundState }> = ({ state }) => {

    const addPoints = useAddPoints()
    const subPoints = useSubPoints()
    const startTimer = useStartTimer()
    const resetFirstRound = useResetFirstRound()

    const until = state.timer * 1000

    return (
        <div className="d-flex w-75 flex-column gap-2 h5">
            <div className="mb-3"><b>Odpočet: </b>{until > 0 ? <Countdown until={until} /> : "-:--"}</div>
            <div className="mb-3"><b>Body: {formatScore(state.score)}</b></div>
            <div className="d-flex flex-row justify-content-between gap-3 mt-5">
                <div className="d-flex flex-row gap-3">
                    <button onClick={() => resetFirstRound()} className="btn btn-danger">
                        Reset
                    </button>
                    {until === 0 ?
                        <button onClick={() => startTimer()} className={clsx("btn", "btn-primary")}>
                            Zapnout odpočet
                        </button>
                        : null}
                </div>
                <div className="d-flex flex-row gap-3">
                    <button onClick={() => subPoints()} className={clsx("btn", "btn-outline-danger text-light")}>
                        - 5 000
                    </button>
                    <button onClick={() => addPoints()} className={clsx("btn", "btn-outline-success text-light")}>
                        + 5 000
                    </button>
                </div>
            </div>
        </div>
    )
}

const stateToStr = (state: number) => {
    if (state === 1) return "first"
    if (state === 2) return "second"
    return ""
}

const HostPage = () => {
    const { first, second, round } = useAppState();

    const [selectedRound, setSelectedRound] = useState<string>("")
    const selectRound = useSelectRound();

    useEffect(() => {
        setSelectedRound(stateToStr(round))
    }, [round])

    const handleSelectRound = useCallback((round: "" | "first" | "second") => {
        setSelectedRound(round)
        selectRound(round)
        return false;
    }, [selectRound])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSelectedRound(stateToStr(round))
        }, 1000)
        return () => clearTimeout(timeout)
    }, [selectedRound, round])



    return <div className="page">
        <div className="d-flex flex-row justify-self-start gap-3 mb-5 pb-5 game-buttons">
            <button onClick={() => handleSelectRound("")} className={clsx("btn", round === 0 ? "active" : false)}>
                POUZE KAMERA
            </button>
            <button onClick={() => handleSelectRound("first")} className={clsx("btn",
                round === 1 ? "active" : false)}>
                I. KOLO
            </button>
            <button onClick={() => handleSelectRound("second")} className={clsx("btn",
                round === 2 ? "active" : false)}>
                II. KOLO
            </button>
        </div>

        {round === 1 ? <FirstRound state={first} /> : null}
        {round === 2 ? <SecondRound state={second} /> : null}
    </div>
}

export default HostPage