import { initializeApp, getApps } from "firebase/app";
import {
    getAuth,
} from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDjKZCvxgVvMJVxaJmP3F8hb8JeieXVWCI",
    authDomain: "marekl.firebaseapp.com",
    databaseURL: "https://marekl-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "marekl",
    storageBucket: "marekl.appspot.com",
    messagingSenderId: "738590419817",
    appId: "1:738590419817:web:d180fc2fc3111825c52bf8"
};

export const firebaseApp =
    getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
export const auth = getAuth(firebaseApp);
export const db = getDatabase(firebaseApp);
