import ReactCountdown, { CountdownRendererFn } from "react-countdown"

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

const renderer: CountdownRendererFn = ({ days: d, hours: h, minutes: m, seconds, completed }) => {
    if (completed) return "0:00";

    const minutes = d * 24 * 60 + h * 60 + m
    return <span>{minutes}:{zeroPad(seconds, 2)}</span>;
};

const Countdown: React.FC<{ until: number }> = ({ until }) => {
    return (<ReactCountdown date={until} renderer={renderer} />)
}

export default Countdown