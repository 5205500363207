import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import ChaserPage from './pages/ChaserPage';
import PlayerPage from './pages/PlayerPage';
import HomePage from './pages/HomePage';
import AudiencePage from './pages/AudiencePage';
import HostPage from './pages/HostPage';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/chaser" element={<ChaserPage />} />
        <Route path="/player" element={<PlayerPage />} />
        <Route path="/audience" element={<AudiencePage />} />
        <Route path="/host" element={<HostPage />} />
      </Routes>
    </Router>
  );
}

export default App;
