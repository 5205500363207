import { useEffect, useRef } from "react";
import { SecondRoundState } from "../../models";
import useSound from "use-sound";

import revealQuestionSound from './sounds/revealQuestion.wav'
import playerAnswerSound from './sounds/playerAnswer.wav'
import chaserAnswerSound from './sounds/chaserAnswer.wav'

import playerChoiceSound from './sounds/playerChoice.mp3'
import chaserChoiceSound from './sounds/chaserChoice.mp3'
import correctAnswerSound from './sounds/correctAnswer.mp3'

const useSounds = (state: SecondRoundState) => {
    const prevState = useRef<SecondRoundState>(state);

    const [playRevealQuestionSound] = useSound(revealQuestionSound, {
        volume: 0.6
    })
    const [playPlayerAnswerSound] = useSound(playerAnswerSound)
    const [playChaserAnswerSound] = useSound(chaserAnswerSound)
    const [playPlayerChoiceSound] = useSound(playerChoiceSound)
    const [playChaserChoiceSound] = useSound(chaserChoiceSound)
    const [playCorrectAnswerSound] = useSound(correctAnswerSound)

    useEffect(() => {
        if (!prevState.current.questionShown && state.questionShown)
            playRevealQuestionSound()

        if (!prevState.current.playerAnswer && state.playerAnswer)
            playPlayerAnswerSound()

        if (!prevState.current.chaserAnswer && state.chaserAnswer)
            playChaserAnswerSound()

        if (prevState.current.answerStage === 0 && state.answerStage === 1)
            playPlayerChoiceSound()

        if (prevState.current.answerStage === 1 && state.answerStage === 2)
            playCorrectAnswerSound()

        if (prevState.current.answerStage === 2 && state.answerStage === 3)
            playChaserChoiceSound()

        prevState.current = state

        // eslint-disable-next-line
    }, [state])

}

export default useSounds