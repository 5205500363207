import axios from "axios";
import { useCallback } from "react";

const client = axios.create({
    baseURL: "https://chaser.marekl.cz/api"
    //baseURL: "http://localhost:8080/api"
});

export const useSelectAnswer = (entity: "chaser" | "player") => {
    const selectAnswer = useCallback((data: { answer: string }) => {
        client.post(`/answer/${entity}`, data)
            .catch(error => console.error(error))
    }, [entity])

    return selectAnswer
}

export const useShowNextQuestion = () => {
    const nextQuestion = useCallback(() => {
        client.post(`/state/second/nextQuestion`)
            .catch(error => console.error(error))
    }, [])

    return nextQuestion
}

export const useNextStage = () => {
    const nextStage = useCallback(() => {
        client.post(`/state/second/next`)
            .catch(error => console.error(error))
    }, [])

    return nextStage
}

export const useStartTimer = () => {
    const startTimer = useCallback(() => {
        client.post(`/state/first/startTimer`)
            .catch(error => console.error(error))
    }, [])

    return startTimer
}

export const useAddPoints = () => {
    const addPoints = useCallback(() => {
        client.post(`/state/first/addPoints`)
            .catch(error => console.error(error))
    }, [])

    return addPoints
}

export const useSubPoints = () => {
    const subPoints = useCallback(() => {
        client.post(`/state/first/subPoints`)
            .catch(error => console.error(error))
    }, [])

    return subPoints
}

export const useResetFirstRound = () => {
    const resetFirstRound = useCallback(() => {
        client.post(`/state/reset?round=first`)
            .catch(error => console.error(error))
    }, [])

    return resetFirstRound
}

export const useSelectRound = () => {
    const selectRound = useCallback((round: "" | "first" | "second") => {
        client.post(`/state/selectRound?round=${round}`)
            .catch(error => console.error(error))
    }, [])

    return selectRound
}
