import { useCallback, useEffect, useState } from "react";
import GameButtons from "../components/GameButtons";
import { useAppState } from "../data/AppStateProvider"
import { useSelectAnswer } from "../api/client";

const ChaserPage = () => {
    const { second: state, round } = useAppState();
    const [selectedAnswer, setSelectedAnswer] = useState<string>("")
    const selectAnswer = useSelectAnswer("chaser")

    useEffect(() => {
        setSelectedAnswer(state.chaserAnswer)
    }, [state.chaserAnswer])

    const handleAnswerSelected = useCallback((answer: string) => {
        setSelectedAnswer(answer)
        selectAnswer({ answer: answer })
        return false;
    }, [selectAnswer])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSelectedAnswer(state.chaserAnswer)
        }, 1000)
        return () => clearTimeout(timeout)
    }, [state.chaserAnswer, selectedAnswer])

    return <div className="page">
        {round === 2 && state.question?.text && state.questionShown ?
            <div className="mb-5 pb-5" style={{ width: "80vw" }}>
                <h4 style={{ fontSize: "2.5vw", textAlign: "center" }}>{state.question.text}</h4><br />
            </div> : null}
        {round === 2 ?
            <GameButtons allowAnswers={state.allowAnswers} question={state.question} selectedAnswer={selectedAnswer} showCorrect={state.answerStage >= 2}
                choicesShown={state.choicesShown} disabled={selectedAnswer !== ""} onAnswerSelected={handleAnswerSelected} />
            : null}
    </div>
}

export default ChaserPage