import Webcam from "react-webcam";
import QuestionBox from "../components/QuestionBox";
import { useAppState } from "../data/AppStateProvider"
import TimerBox from "../components/TimerBox";

const AudiencePage = () => {
    const { first, second, round } = useAppState();

    return (
        <div className="page">
            <Webcam audio={false} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }} />
            {round === 1 ?
                <div style={{ position: 'absolute', bottom: "12.5vh" }}>
                    <TimerBox score={first.score} timer={first.timer} />
                </div>
                : null}
            {round === 2 && second.question ?
                <div style={{ position: 'absolute', bottom: "10vh" }}>
                    <QuestionBox state={second} />
                </div>
                : null}
        </div>
    )

}

export default AudiencePage