import { Link } from "react-router-dom"

const HomePage = () => {
    return <div className="page">
        <div className="d-flex gap-3 flex-column align-items-center justify-content-stretch">
            <Link to="/chaser" className="btn btn-lg btn-light w-100">Lovec</Link>
            <Link to="/player" className="btn btn-lg btn-light w-100">Hráč</Link>
            <Link to="/audience" className="btn btn-lg btn-light w-100">Publikum</Link>
            <Link to="/host" className="btn btn-lg btn-light w-100">Moderátor</Link>
        </div>
    </div>
}

export default HomePage